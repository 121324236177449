/* global Modernizr, $ */

window.onload = function() {
  setRequiredDates();
  bindCustomDateFields();
};

// Make dates required if necessary
//  For touch-devices, use the <input type=date>-field
//  For non-touch-devices, use three input-fields to input the date
function setRequiredDates() {
  var datesList = document.querySelectorAll('.form__item--date--required');
  for (var i = 0; i < datesList.length; ++i) {
    var dates = datesList[i];
    var name = dates.getAttribute('name');
    if (Modernizr && Modernizr.touchevents) {
      var completeDate = document.querySelector('#' + name);
      completeDate.setAttribute('required', 'required');
    } else {
      document.querySelector('#' + name + '_1').setAttribute('required', 'required');
      document.querySelector('#' + name + '_2').setAttribute('required', 'required');
      document.querySelector('#' + name + '_3').setAttribute('required', 'required');
    }
  }
}

// For non-touch-devices, use a custom date entry
//  It uses three input-fields to fill the <input type=date>-field
function bindCustomDateFields() {
  var datesList = document.querySelectorAll('.form__item--date');
  for (var i = 0; i < datesList.length; ++i) {
    var dates = datesList[i];
    var dateHelpers = dates.querySelectorAll('.form__control--date-helper');
    var name = dates.getAttribute('name');
    var completeDate = document.querySelector('#' + name);

    for (var j = 0; j < dateHelpers.length; ++j) {
      var dateHelper = dateHelpers[j];
      dateHelper.oninput = function() {
        var day = document.querySelector('#' + name + '_1');
        var month = document.querySelector('#' + name + '_2');
        var year = document.querySelector('#' + name + '_3');

        completeDate.value = year.value + '-' + month.value + '-' + day.value;
      };
    }
  }
}

// Toggle Menu
document.addEventListener('DOMContentLoaded', function() {
  document.querySelector('.topmenu__item--toggle').addEventListener('click', function(e) {
    document.querySelector('body').classList.toggle('show-menu');
    e.preventDefault();
  });
});

// Topmenu functions
$(function() {
  $(".topmenu__item").hover(function(){
    $(this).addClass("hover");
    $('ul:first',this).css('visibility', 'visible');
  }, function(){
    $(this).removeClass("hover");
    $('ul:first',this).css('visibility', 'hidden');
  });
  $("ul.topmenu li:has(ul)").find("a:first").append('<i class="fa fa-angle-down"></i>');
});

// Initialize owl slider
var appointmentCalendar;
$(function() {
  if ($('.slides .slides__slide').length > 1) {
    $('.slides').addClass('owl-carousel').owlCarousel({
      itemElement: 'div',
      loop: true,
      items: 1,
      margin: 0,
      nav: true,
      dots: true,
      navText: ['', '']
    });
  }

  // Owl-carousel calendar
  appointmentCalendar = $('.appointment__weeks').owlCarousel({
    items: 1
  });

  $('.weeks__next').on('click', function() {
    appointmentCalendar.trigger('next.owl.carousel');
  });
  $('.weeks__previous').on('click', function() {
    appointmentCalendar.trigger('prev.owl.carousel');
  });

  // Select date of calendar
  $('.calendar__time').on('click', function() {
    if ($(this).closest('.calendar__day--disabled').length <= 0) {
      $('.calendar__time').removeClass('calendar__time--selected');
      $(this).addClass('calendar__time--selected');
      $('input[name="appointment__date"]').val($(this).attr('data-date'));
    }
  });
});

// Scroll to top
$(function() {
  $('.scroll-to-top').on('click', function() {
    var body = $('html, body');
    body.stop().animate({scrollTop:0}, '500');
  });

  $(window).on('scroll', function() {
    if (document.body.scrollTop >= 500) {
      $('.scroll-to-top').addClass('scroll-to-top--active');
    } else {
      $('.scroll-to-top').removeClass('scroll-to-top--active');
    }
  });
});
